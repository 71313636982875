// general resets

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}


button{
  outline: none;
  border: none;
}


// imports

@font-face {
  font-family: VCR;
  src: url(/fonts/VCR.ttf);
}

// type
li {
  list-style: none;
  padding: .5rem;
  text-decoration: underline #ffffff;
}

h1,
h2,
h3 {
  font-family: VCR;
  color: #ffffff;
  text-align: center;
}
h1 {
  margin-bottom: 20px;
}
h2,
p ,a{
  color: #000000;
  text-align: center;
  padding: 0.5rem;
  max-width: 600px;
  margin: auto;
  padding-top: 20px;
  text-decoration: none;

}
h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
h3 {
  font-size: 1.5rem;
}
h5 {
  color: #ffffff;
}

p,
div,
h4 {
  font-family: "Manrope", sans-serif;
}

.img {
  max-width: 60px;
  max-height: 60px;
}

// Top level styling

.App {
  // background: linear-gradient(180deg, #407EC7 31%, #81b2eb 65%, #4C8AD4 80%);
  background: url(/img/bgMid.png);
  background-size: cover;
  // background-position: top;
}

.flex-container {
  display: flex;
  justify-content: center;
  width: 100%;
  // align-items: center;
}
    .flex1 {
      flex: 1;
      
    }


.active .link{
  background: #ffffff66;
}

span{
  // font-size: 40px;
  line-height: .9;
  text-decoration: none;
}
blockquote{
color: #000000;
font-size: 1rem;
}


