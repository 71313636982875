.swiper-container{
  width:95%;
  max-width:900px;
  margin:  3rem auto;
}
.swiper-slide{
  display:flex;
  justify-content: center;
  align-items: center;
}


.swiper-button-next, .swiper-button-prev{
  background: url(/img/ArrowRight.png);
  background-size:contain;
  background-position:center;
  background-repeat: no-repeat;
&:after{
  content:none !important;
}
  
}
 .swiper-button-prev{
  background: url(/img/ArrowLeft.png);
  background-size:contain;
  background-position:center;
  background-repeat: no-repeat;
 }

